<template>
  <div class="mainbox">
    <div class="date-plug">
      <el-select @change="dataTypeChange" v-model="dateType" placeholder="请选择" style="width:90px">
        <el-option v-for="(item,index) in dateTypes" :key="index" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-date-picker  
        v-if="dateType=='1d,-8h'" 
        v-model="dateDay"
        key="day" 
        type="daterange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <el-date-picker  
        v-else-if="dateType=='1M'" 
        v-model="dateDay" 
        key="month" 
        type="monthrange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
      <div style="display:inline" v-else>
        <el-date-picker
          v-model="yearFirst"
          type="year"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择起始年">
        </el-date-picker>
        <span>至</span>
        <el-date-picker
          v-model="yearLast"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="year"
          placeholder="选择结束年">
        </el-date-picker>
      </div>
      <el-button @click="searchData" style="padding:.2rem 12px" size="mini" type="primary">查询</el-button>
    </div>
    <div class="left">
      <div class="tongji">
        <li>
          <div class="left"><img src="../../assets/images/e7.png"></div>
          <div class="right">
            <span>企业</span><p class="f_blue"><b>{{companyNum}}</b>家</p>
          </div>
        </li>
        <li>
          <div class="left"><img src="../../assets/images/e1.png"></div>
          <div class="right">
            <span>重点用能设备</span><p class="f_green"><b>{{impDevNum}}</b>台</p>
          </div>
        </li>
        <li>
          <div class="left"><img src="../../assets/images/e2.png"></div>
          <div class="right">
            <span>计量器具</span><p class="f_yellow1"><b>{{toolsNum}}</b>件</p>
          </div>
        </li>
      </div>
      <div class="modulebox" 
        v-loading="powerLoading"
        element-loading-text="获取数据中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="title"><span>能源统计</span></div>
        <div class="content energylist">
          <li v-for="(item,index) in energylist" :key="index">
            <div class="left"><img :src='item.src'></div>
            <div class="right">
              <span>{{item.title}}</span>
              <p>
                <b>{{item.value}}
                </b>{{item.dw}}
              </p>
            </div>
          </li>
        </div>
      </div>
      <div class="modulebox f_column">
        <div class="f_row">
          <div 
            class="f_column" 
            style="margin-right:10px;width:31%;flex:none;"
            v-loading="powerLoading"
            element-loading-text="获取数据中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="title"><span>能源消费占比对比分析</span></div>
            <div class="content">
              <div ref="pie1" class="echarts"></div>
            </div>
          </div>
          <div 
            class="f_column"
            v-loading="industyLoading"
            element-loading-text="获取数据中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="title"><span>能源使用占比对比分析</span></div>
            <div class="content">
              <div ref="pie2" class="echarts"></div>
            </div>
          </div>
        </div>
        <div class="f_row">
          <div 
            class="f_column" 
            style="margin-right:10px"
            v-loading="powerLoading"
            element-loading-text="获取数据中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="title"><span>能源使用占比对比分析</span></div>
            <div class="content">
              <div ref="bar1" class="echarts"></div>
            </div>
          </div>
          <div 
            class="f_column"
            v-loading="monthUseLoading"
            element-loading-text="获取数据中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="title"><span>能源消耗月使用量</span></div>
            <div class="content">
              <div ref="line1" class="echarts"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="titlebox">
          <div class="title"><span>区域能耗</span></div>
          <div class="select">
            地区选择
            <el-cascader
              ref="areaPower"
              clearable
              v-model="areaEnengy"
              :options="areaData"
              :props="{ 
                checkStrictly: true,
                value:'regionCode',
                label:'regionName',
                children:'nodes',
              }"
              @change="handleChange">
            </el-cascader>
          </div>
        </div>
        <div class="content energyregion" 
          v-loading="areaLoading"
          element-loading-text="获取数据中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div>
            <h3>各区域能源使用量</h3>
            <div ref="bar2" class="echarts"></div>
          </div>
          <div>
            <h3>各区域能源使用占比分析</h3>
            <div ref="pie3" class="echarts"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="tongji tongji1">
        <li>
          <div class="left"><img src="../../assets/images/e3.png"></div>
          <div class="right">
            <span>电表</span><p class="f_red"><b>{{powerRota}}</b>台</p>
          </div>
        </li>
        <li>
          <div class="left"><img src="../../assets/images/e4.png"></div>
          <div class="right">
            <span>水表</span><p class="f_pink"><b>{{waterRota}}</b>台</p>
          </div>
        </li>
        <li>
          <div class="left"><img src="../../assets/images/e6.png"></div>
          <div class="right">
            <span>气表</span><p class="f_yellow"><b>{{gasRota}}</b>台</p>
          </div>
        </li>
        <li>
          <div class="left"><img src="../../assets/images/e5.png"></div>
          <div class="right">
            <span>网关</span><p class="f_green"><b>{{gateWay}}</b>台</p>
          </div>
        </li>
      </div>
      <div class="modulebox f_column">
        <div class="f_row">
          <div class="f_column" style="margin-right:10px">
            <div class="title"><span>服务类别</span></div>
            <div class="content" style="padding:0px .6rem">
              <div ref="rightpie1" class="echarts"></div>
            </div>
          </div>
          <div class="f_column">
            <div class="title"><span>服务价值</span></div>
            <div class="content servicevalue">
              <li v-for="(item,index) in servicevalue" :key="index">
                <span>{{item.title}}：</span><span><b>{{item.value}}</b>{{item.dw}}</span>
              </li>
            </div>
          </div>
        </div>
        <div 
          class="f_row"
          v-loading="eightLoading"
          element-loading-text="获取数据中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div class="f_column" style="margin-right:10px">
            <div class="title"><span>八大高能耗行业重点用能综合能耗</span></div>
            <div class="content">
              <div ref="rightbar1" class="echarts"></div>
            </div>
          </div>
          <div 
            class="f_column"
            v-loading="powerDBLoading"
            element-loading-text="获取数据中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="title"><span>能耗对比</span></div>
            <div class="content energycontrast">
              <li v-for="(item,index) in energycontrast" :key="index">
                <p>{{item.title}}：</p>
                <p>
                  <span>
                    <b>{{item.value}}</b>{{item.dw}}
                  </span>
                  <span>同比：
                    <b :class="item.num=='up'?'f_green':'f_red'">{{item.number}}
                      <i :class="item.num=='up'?'el-icon-top':'el-icon-bottom'"></i>
                    </b>
                  </span>
                </p>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="f_row">
          <div 
            class="f_column" 
            style="margin-right:10px"
            v-loading="industyLoading"
            element-loading-text="获取数据中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <div class="title"><span>行业监测企业数能耗排名</span></div>
            <div class="content">
              <div ref="rightbar2" class="echarts"></div>
            </div>
          </div>
          <div class="f_column">
            <div class="title"><span>增值服务企业数占比</span></div>
            <div class="content">
              <div ref="rightline1" class="echarts"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="titlebox">
          <div class="title"><span>用能占比</span></div>
          <div class="select">
            地区选择
            <el-cascader
              ref="areaPower"
              clearable
              v-model="areaEnengyPark"
              :options="areaData"
              :props="{ 
                checkStrictly: true,
                value:'regionCode',
                label:'regionName',
                children:'nodes',
              }"
              @change="handleChangePark">
            </el-cascader>
          </div>
        </div>
        <div class="content energygdp">
          <div>
            <h3>企业用电占比比例</h3>
            <div ref="rightline2" class="echarts"></div>
          </div>
          <div>
            <h3>各园区服务企业用能统计</h3>
            <div ref="rightbar3" class="echarts"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require('echarts');
import { compare }  from '@/assets/js/common'
import api from '@/apis/Power/energy'
import common from '@/components/monitorCommon/common'
export default {
  props:['globalDate'],
  components:{
  },
  data() {
    return {
      areaLoading:false,//左下角区域加载状态
      powerLoading:false,//左上二层能源统计加载状态
      industyLoading:false,//左右中间两侧能源使用行业分析
      eightLoading:false,//八大高能耗行业重点用能综合能耗
      powerDBLoading:false,//能耗对比
      monthUseLoading:false,//能源消耗月使用量

      //左上
      companyNum:'--',//企业数
      impDevNum:'--',//重点设备数
      toolsNum:'--',//器具数
      //右上
      powerRota:'--',//电表
      waterRota:'--',//水表
      gasRota:'--',//气表
      gateWay:'--',//网关
      //左下-右下
      areaEnengy:"",//区域能耗地方
      areaEnengyPark:"",//能耗园区
      areaData:[],//区域数据
      //顶部时间栏
      dateDay:[new Date(new Date().getTime()-3600 * 1000 * 24 * 30).Format("yyyy-MM-dd hh:mm:ss"),new Date().Format("yyyy-MM-dd hh:mm:ss")],//关联日期
      yearFirst: '',//关联起始年
      yearLast: '',//关联结束年
      dateType:'1M',//年月日
      dateTypes:[
        {id:0,name:'日',value:'1d,-8h'},
        {id:1,name:'月',value:'1M'},
        {id:2,name:'年',value:'1Y'},
      ],
      //区域树数据
      options:[],
      //默认数据
      energylist:[
        {id:0,title:'电力',value:'0',dw:'万千瓦时',src:require('@/assets/images/s1.png')},
        {id:1,title:'水',value:'0',dw:'万吨',src:require('@/assets/images/s2.png')},
        {id:2,title:'煤碳',value:'0',dw:'万吨',src:require('@/assets/images/s3.png')},
        {id:3,title:'石油',value:'0',dw:'万升',src:require('@/assets/images/s4.png')},
        {id:4,title:'天然气',value:'0',dw:'万m³',src:require('@/assets/images/s5.png')},
      ],
      servicevalue:[
        {id:0,title:'直购电交易量',value:'0',dw:'万度'},
        {id:1,title:'减少碳排放',value:'0',dw:'吨'},
        {id:2,title:'降低费用',value:'0',dw:'万元'},
        {id:3,title:'碳交易额',value:'0',dw:'万元'},
      ],
      energycontrast:[
        {id:0,title:'综合能源消耗超30000吨企业',value:'0',dw:'家',number:'0%',num:'up'},
        {id:1,title:'综合能源消耗超10000吨企业',value:'0',dw:'家',number:'0%',num:'up'},
        {id:2,title:'综合能源消耗超5000吨企业',value:'0',dw:'家',number:'0%',num:'up'},
      ]
    };
  },
  methods:{
    //获取企业用电占比
    getCompanyPowerZb(){
      api.getCompanyEnergyPro().then(res=>{
        if(res.code==10000){
          if(res.data){
            let _data = res.data
            let _year = []
            let _basic = []
            let _contr = []
            for(var i in _data){
              let _sum = _data[i].basic+_data[i].contract
              _year.push(_data[i].date.substring(0,4))
              if(_sum==0){
                _basic.push(0)
                _contr.push(0)
              }else{
                _basic.push((_data[i].basic/_sum*100).toFixed(2))
                _contr.push((_data[i].contract/_sum*100).toFixed(2))
              }
            }
            this.rightline2(_year,_basic,_contr)
          }
        }
      })
    },
    //获取园区企业用能统计
    getParking(val){
      let params = {
        regionCode:val
      }
      api.getParkEnergy({params}).then(res=>{
        if(res.code==10000){
          let _data = res.data
          if(_data){
            let _name = [];
            let _sum = []
            for(var i in _data){
              _name.push(_data[i].name);
              _sum.push(_data[i].sum.toFixed(2))
            }
            this.rightbar3(_name,_sum)
          }
        }else{
          this.$message.error('获取园区用能统计失败');
          return;
        }
      })
    },
    //获取能源消耗月使用量
    getMonthPower(){
      this.monthUseLoading = true;
      api.getEnergyMonth().then(res=>{
        if(res.code==10000){
          if(res.data){
            let _data = res.data
            let obj = {
              _power:[],
              _water:[],
              _gas:[],
              _coal:[],
              _oil:[],
            }
            for(var i in _data){
              obj._power.push((_data[i].power*1.229/10000).toFixed(2))
              obj._water.push((_data[i].water*0.0857/10000).toFixed(2))
              obj._gas.push((_data[i].gas*13.3/10000).toFixed(2))
              obj._coal.push((_data[i].coal*0.7143/10000).toFixed(2))
              obj._oil.push((_data[i].oil*1.4286/10000).toFixed(2))
            }
            this.line1(obj)
            this.monthUseLoading = false;
          }
        }else{
          this.$message.error('获取能源月消耗量失败');
          this.monthUseLoading = false;
          return;
        }
      })
    },
    //获取增值企业占比
    getUpCompany(){
      api.getIncrementCompany().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            let _time = [];
            let _sum = [];
            for(let i in res.data){
              _time.push(i.substring(0,4))
              _sum.push(res.data[i])
            }
            this.rightline1(_time,_sum)
          }
        }else{
          this.$message.error('获取增值占比失败');
          return;
        }
      })
    },
    //查询左右中间两侧能源使用行业分析
    getTradeAnal(){
      let params = {
        startTime:this.dateDay[0],
        endTime:this.dateDay[1],
        groupTime:this.dateType
      }
      this.industyLoading = true
      api.getCompanyTrade({params}).then(res=>{
        if(res.code==10000){
          let _data = res.data
          if(_data){
            let _out = [];
            let _put = []
            for(var i = 0;i<=5;i++){
              let obj = {}
              obj.value = _data[i]?_data[i].companySize:0
              obj.name = _data[i]?_data[i].trade:'--'
              if(obj.name=='信息传输、软件和信息技术服务业'){
                obj.name = '信息软件业'
              }
              _out.push(obj) 
            }
            for(var j = 6;j<=11;j++){
              let objs = {}
              objs.value = _data[j]?_data[j].companySize:0
              objs.name = _data[j]?_data[j].trade:'--'
              if(objs.name=='信息传输、软件和信息技术服务业'){
                objs.name = '信息软件业'
              }
              _put.push(objs) 
            }
            this.pie2(_out,_put)
            this.rightbar2(_data)
            this.industyLoading = false;
          }
        }else{
          this.$message.error('获取行业分析失败')
          this.industyLoading = false;
          return;
        }
      })
    },
    //查询右上角第二层服务类别
    searchSeverType(){
      api.getServiceModel().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            let _data = res.data
            let _sum = _data.synth+_data.direct+_data.basic+_data.direct+_data.ops
            this.myfun(_data,_sum)
            this.servicevalue[0].value = (_data.directData/10000).toFixed(2)
            this.servicevalue[1].value = _data.carbonEmission.toFixed(2)
            this.servicevalue[2].value = (_data.reduceCosts/10000).toFixed(2)
            this.servicevalue[3].value = (_data.carbonTrading/10000).toFixed(2)
          }
        }else{
          this.$message.error('获取服务类别失败');
          return;
        }
      })
    },
    //获取各区域数据
    getAreaPowerData(){
      // let _code = JSON.parse(localStorage.getItem('regionCode'))[0]
      api.getRegionChild().then(res=>{
        if(res.code==10000){
          this.areaData = res.data
          this.areaEnengy = ["510600"]
          this.areaEnengyPark = ["510600"]
        }else{
          this.$message.error('获取区域树失败');
          return;
        }
      })
    },
    //各区域能源-级联选择器
    handleChange(val) {
      sessionStorage.setItem('nowArea',val[val.length-1])
      this.getAreaPowerPart(val[val.length-1])
    },
    //各园区统计-级联选择器
    handleChangePark(val) {
      sessionStorage.setItem('nowAreaPark',val[val.length-1])
      this.getParking(val[val.length-1])
    },
    //获取左下角各区域能源使用占比分析
    getAreaPowerPart(value){
      let params = {
        startTime:this.dateDay[0],
        endTime:this.dateDay[1],
        groupTime:this.dateType,
        regionCode:value
      }
      this.areaLoading = true
      api.getCompanyArea({params}).then(res=>{
        if(res.code==10000){
          if(res.data){
            let _data = res.data
            let _name = []
            let _power = []
            let _water = []
            let _gas = []
            let _coal = []
            let _oil = []
            let _arr = _data.map((item,index)=>{
              if(index<=5){
                return{
                  value:(item.powerData.total/10000).toFixed(2),
                  name:item.regionName
                }
              }
            })
            for(var i in _data){
              if(i<=5){
                _name.push(_data[i].regionName)
                _power.push((_data[i].powerData.power/10000).toFixed(2))
                _water.push((_data[i].powerData.water/10000).toFixed(2))
                _gas.push((_data[i].powerData.gas/10000).toFixed(2))
                _coal.push((_data[i].powerData.coal/10000).toFixed(2))
                _oil.push((_data[i].powerData.oil/10000).toFixed(2))
              }
            }
            this.bar2(_name,_power,_water,_gas,_coal,_oil)
            this.pie3(_arr)
            this.areaLoading = false
          }
        }else{
          this.$message.error('获取能源使用占比失败')
          this.areaLoading = false
          return;
        }
      })
    },
    //获取能耗对比
    getPowerDB(){
      this.powerDBLoading = true;
      api.getEnergyContrast().then(res=>{
        if(res.code==10000){
          if(res.data){
            let _data = res.data
            let jnumthis30000 = 0;
            let jnumthis10000 = 0;
            let jnumthis5000 = 0;
            let jnumlast30000 = 0;
            let jnumlast10000 = 0;
            let jnumlast5000 = 0;
            for(var i in _data){
              //this
              if(_data[i].thisYear>=30000){
                jnumthis30000++
              }
              if(_data[i].thisYear>=10000){
                jnumthis10000++
              }
              if(_data[i].thisYear>=5000){
                jnumthis5000++
              }
              //last
              if(_data[i].lastYearData>=30000){
                jnumlast30000++
              }
              if(_data[i].lastYearData>=10000){
                jnumlast10000++
              }
              if(_data[i].lastYearData>=5000){
                jnumlast5000++
              }
            }
            this.energycontrast = [
              {id:0,title:'综合能源消耗超30000吨企业',value:jnumthis30000,dw:'家',
                number:jnumthis30000==0||jnumlast30000==0?0+'%':
                ((jnumthis30000-jnumlast30000)/jnumlast30000*100).toFixed(2)+'%',
                num:jnumthis30000-jnumlast30000>=0?'up':'down'
              },
              {id:1,title:'综合能源消耗超10000吨企业',value:jnumthis10000,dw:'家',
                number:jnumthis10000==0||jnumlast10000==0?0+'%':
                ((jnumthis10000-jnumlast10000)/jnumlast10000*100).toFixed(2)+'%',
                num:jnumthis10000-jnumlast10000>=0?'up':'down'
              },
              {id:2,title:'综合能源消耗超5000吨企业',value:jnumthis5000,dw:'家',
                number:jnumthis5000==0||jnumlast5000==0?0+'%':
                ((jnumthis5000-jnumlast5000)/jnumlast5000*100).toFixed(2)+'%',
                num:jnumthis5000-jnumlast5000>=0?'up':'down'
              },
            ]
            this.powerDBLoading = false;
          }
        }else{
          this.$message.error('获取能耗对比失败');
          this.powerDBLoading = false;
          return;
        }
      })
    },
    //获取八大高能耗行业重点用能综合能耗
    getEightEnergyMax(){
      this.eightLoading = true;
      api.getEightTrade().then(res=>{
        if(res.code==10000){
          if(res.data){
            let _data = res.data
            let _nameArr = []
            let _thisYear = []
            let _lastYear = []
            let _tb = []
            for(var i in _data){
              _nameArr.push(_data[i].trade)
              if(!_data[i].thisYearData){
                _data[i].thisYearData = 0
              }
              if(!_data[i].lastYearData){
                _data[i].lastYearData = 0
              }
              _thisYear.push((_data[i].thisYearData).toFixed(2))
              _lastYear.push((_data[i].lastYearData).toFixed(2))
              _tb.push(
                _data[i].thisYearData==0||_data[i].lastYearData==0?0:
                (((_data[i].thisYearData-_data[i].lastYearData)/_data[i].lastYearData)*100).toFixed(2)
              )
            }
            this.rightbar1(_nameArr,_thisYear,_lastYear,_tb)
            this.eightLoading = false;
          }
        }else{
          this.$message.error('获取八大高能耗行业失败');
          this.eightLoading = false;
          return;
        }
      })
    },
    //获取能源统计
    getEnergyCount(){
      let params = {
        startTime:this.dateDay[0],
        endTime:this.dateDay[1],
        groupTime:this.dateType
      }
      this.powerLoading = true;
      api.getCompanyEnergy({params}).then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            let _power = res.data.power/10000
            let _water = res.data.water/10000
            let _gas = res.data.coal/10000
            let _coal = res.data.oil/10000
            let _oil = res.data.gas/10000
            this.energylist[0].value = _power.toFixed(2)
            this.energylist[1].value = _water.toFixed(2)
            this.energylist[2].value = _coal.toFixed(2)
            this.energylist[3].value = _oil.toFixed(2)
            this.energylist[4].value = _gas.toFixed(2)
            this.pie1(
              _power.toFixed(2),
              _water.toFixed(2),
              _gas.toFixed(2),
              _coal.toFixed(2),
              _oil.toFixed(2),
            )
            this.bar1([
              (_power*1.229).toFixed(2),
              (_water*0.0857).toFixed(2),
              (_gas*13.3).toFixed(2),
              (_coal*0.7143).toFixed(2),
              (_oil*1.4286).toFixed(2),
            ])
            this.powerLoading = false;
          }
        }else{
          this.$message.error('获取能源统计失败');
          this.powerLoading = false;
          return;
        }
      })
    },
    //获取左上角企业数等数量
    getCompanyDevNum(){
      api.getCompanyDevCount().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.companyNum = res.data.company
            this.impDevNum = res.data.energyDev
            this.toolsNum = res.data.devices
          }
        }else{
          this.$message.error('获取企业数量失败');
          return;
        }
      })
    },
    //获取右上角电水气表等数量
    getRotaNumber(){
      api.getCompanyIotDevCount().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.powerRota = res.data.epCount
            this.waterRota = res.data.wpCount
            this.gasRota = res.data.gasCount
            this.gateWay = res.data.gatewayCount
          }
        }else{
          this.$message.error('获取采集器数量失败');
          return;
        }
      })
    },
    //日期类型切换
    dataTypeChange(val){
      if(val=='1Y'){
        this.yearFirst = ''
        this.yearLast = ''
      }else{
        this.dateDay = ''
      }
    },
    //确定按钮
    searchData(){
      let _end = setInterval(function(){},2000);
      for(let i =1;i<=_end;i++){
        clearInterval(i)
      }
      if(this.dateType=='1d,-8h'&&this.dateDay == ''){
          this.$message.warning('请选择时间');
          return;  
      }
      if(this.dateType=='1M'&&this.dateDay == ''){
          this.$message.warning('请选择时间');
          return;  
      }
      if(this.dateType=='1Y'){
        if(!this.yearFirst||!this.yearLast){
          this.$message.warning('请选择起止年份');
          return;
        }
        if(new Date(this.yearFirst).getTime()<new Date(this.yearLast).getTime()){
          this.dateDay = [this.yearFirst,this.yearLast]
        }else{
          this.$message.warning('开始时间不能大于结束时间');
          return;
        }
      }
      let _codesNow = sessionStorage.getItem('nowArea')
      // let _codesLast = JSON.parse(localStorage.getItem('regionCode'))[0]
      if(_codesNow){
        this.getAreaPowerPart(_codesNow)
      }else{
        // this.getAreaPowerPart(_codesLast[_codesLast.length-1])
        this.getAreaPowerPart(510600)
      }
      this.getEnergyCount();//获取能源统计
      this.getTradeAnal();//查询左右中间两侧能源使用行业分析
    },

    pie1(a,b,c,d,e) {
			let pie1Chart = echarts.init(this.$refs.pie1)
			pie1Chart.setOption({
        color:['#00ffcc','#00ccff','#eb6098','#b486f3','#ffff00'],
				series: [
          {
            type: 'pie',
            radius: ['30%', '50%'],
            itemStyle: {
              borderColor: '#050f5a',
              borderWidth: 2
            },
            label: {
              show: true, color:'#ccd0f2',fontSize: 20,
              formatter: '{b}\n{v|{d} %}',
              rich: {
                v: {
                  fontSize: 20,lineHeight:30,fontWeight:600,
                  color: '#fff'
                }
              }
            },
            labelLine:{
              length:20,length2:40,
              lineStyle:{ color:'#ccd0f2',width:2}
            },
            data: [
              { value: a, name: '电力' },
              { value: b, name: '水' },
              { value: c, name: '天然气' },
              { value: d, name: '煤碳' },
              { value: e, name: '石油' }
            ]
          }
        ]
			})
		},
    pie2(nei,wai) {
			let pie2Chart = echarts.init(this.$refs.pie2);
      let neiArr = []
      var data1 = nei;
      for(var i in nei){
        neiArr.push(nei[i].name)
      }
			pie2Chart.setOption({
        color:['#f29149','#005ae4','#eb6098','#00ccff','#ffff00','#b486f3','#00ff00','#fac858'],
				legend: {
          orient: 'vertical', icon: "circle",align: 'left',
          right: '0%',top:'center',itemGap:40, itemHeight:18,itemWidth:18,
          textStyle: {
            color: "#ccd0f2",fontSize: 20, lineHeight:18,
            rich: {
              v: {
                color: '#00ffcc',fontSize: 20, fontWeight:600
              }
            }
          },
          data:neiArr,
          formatter: function(name) {
            var value='';
            for (var i = 0, l = data1.length; i < l; i++) {
              if (data1[i].name == name) {
                  value = data1[i].value;
              }
            }
            return name +' '+' '+ ' {v|' + value +'}'
          },
        },
        series: [
        {
          name: '内圆',
          type: 'pie',
          selectedMode: 'single',
          radius: [0, '35%'],
          center:['36%','50%'],
          label: {
            show:false
          },
          itemStyle: {
            borderColor: '#050f5a',
            borderWidth: 2
          },
          data:data1
        },
        {
          name: '外环',
          type: 'pie',
          radius: ['42%', '50%'],
          center:['36%','50%'],
          labelLine:{
            length:20,length2:20,
            lineStyle:{ color:'#ccd0f2',width:2}
          },
          itemStyle: {
            borderColor: '#050f5a',
            borderWidth: 2
          },
          label: {
            formatter: '{b}\n{v|{c}}',color:'#ccd0f2',fontSize: 20,
            rich: {
              v: {
                fontSize: 20,lineHeight:30,fontWeight:600,
                color: '#00ffcc'
              }
            }
          },
          data: wai
        }
      ]
			})
      
		},
    line1(obj){
      let line1Chart = echarts.init(this.$refs.line1);
      common.moveEcharts(12,line1Chart)
			line1Chart.setOption({
        color:['#00ffcc','#00ccff','#ffff00','#eb6098','#b486f3'],
        legend: {
            icon: 'rect',
            itemWidth: 18, itemHeight: 8, itemGap: 20,right: '4%',
            data: ['电', '水', '气', '煤', '油'],
            textStyle: {
                fontSize: 20, color: '#fff'
            }
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          formatter:'{b}(万吨/标煤)<br />{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}<br />{a3}: {c3}<br />{a4}: {c4}<br />',
          axisPointer: {
            type: 'line' ,
          }
        },
        grid: {
            left: '8%',right: '6%', bottom: '18%', top:'12%'
        },
        xAxis: {
            type: 'category', boundaryGap: false,
            data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
        },
        series: [{
            name: '电', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: obj._power
          },{
            name: '水', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: obj._water
          },{
            name: '气', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: obj._gas
          },{
            name: '煤', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: obj._coal
          },{
            name: '油', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: obj._oil
          },
        ]
			})
    },
    bar1(arr){
      let bar1Chart = echarts.init(this.$refs.bar1);
      common.moveEcharts(arr.length,bar1Chart)
			bar1Chart.setOption({
        color:['#ffff00'],
        grid: {
            left: '8%',right: '6%', bottom: '18%', top:'12%'
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          formatter:'{c}万吨/标煤',
          axisPointer: {
            type: 'shadow' 
          }
        },
        xAxis: {
            type: 'category', 
            data: ['电力','水力','天然气','原煤','油'],
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            type: 'value', max:400,
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
        },
        series: [{
            type: 'bar',barWidth: '24%',
            data: arr
          }
        ]
			})
    },
    pie3(arr) {
			let pie3Chart = echarts.init(this.$refs.pie3)
      common.moveEcharts(arr.length,pie3Chart)
			pie3Chart.setOption({
        color:['#00ffcc','#00ccff','#eb6098','#b486f3','#ffff00','#f29149'],
				series: [
          {
            type: 'pie',
            radius: ['30%', '50%'],
            itemStyle: {
              borderColor: '#050f5a',
              borderWidth: 2
            },
            label: {
              show: true, color:'#ccd0f2',fontSize: 20,
              formatter: '{b}\n{v|{d} %}',
              rich: {
                v: {
                  fontSize: 20,lineHeight:30,fontWeight:600,
                  color: '#fff'
                }
              }
            },
            labelLine:{
              length:20,length2:40,
              lineStyle:{ color:'#ccd0f2',width:2}
            },
            data: arr
          }
        ]
			})
		},
    bar2(name,a,b,c,d,e){
      let bar2Chart = echarts.init(this.$refs.bar2);
      common.moveEcharts(name.length,bar2Chart)
			bar2Chart.setOption({
        color:['#fac858','#00ccff','#00ffcc','#eb6098','#ffff00'],
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          formatter:'{b}<br />{a0}: {c0}万/千瓦时<br />{a1}: {c1}万/吨<br />{a2}: {c2}万/m³<br />{a3}: {c3}万/吨<br />{a4}: {c4}万/升<br />',
          axisPointer: {
            type: 'shadow' 
          }
        },
        grid: {
            left: '18%',right: '6%', bottom: '6%', top:'12%'
        },
        legend: {
            icon: 'rect',
            itemWidth: 18, itemHeight: 8, itemGap: 20,right: '10%',
            data: ['电力','水力','天然气','煤碳','石油'],
            textStyle: {
                fontSize: 20, color: '#fff'
            }
        },
        xAxis: {
          type: 'value',show:false
        },
        yAxis: {
          type: 'category', axisLine:{ show:false}, axisTick:{ show:false},
          axisLabel: { color:'#fff',fontSize: 20},
          data: name
        },
        series: [
          {
            name: '电力', type: 'bar', stack: 'total', barWidth:'30%',

            emphasis: {
              focus: 'series'
            },
            data: a
          },
          {
            name: '水力',type: 'bar',stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: b
          },
          {
            name: '天然气',type: 'bar',stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: c
          },
          {
            name: '煤碳',type: 'bar',stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: d
          },
          {
            name: '石油',type: 'bar',stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: e
          },
        ]
			})
    },
    rightbar1(a,b,c,d){
      let rightbar1Chart = echarts.init(this.$refs.rightbar1);
      common.moveEcharts(a.length,rightbar1Chart)
			rightbar1Chart.setOption({
        color:['#ffff00','#00ffcc','#eb6098'],
        legend: {
            right: 30,top:0,
            textStyle: { color: "#fff",fontSize: 20 },itemGap: 30
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          formatter:'{b}<br/>{a0}年: {c0}<br/>{a1}年: {c1}<br/>{a2}: {c2}%',
          axisPointer: {
            type: 'shadow' 
          }
        },
        grid: {
            left: '8%',right: '2%', bottom: '28%', top:'12%'
        },
        xAxis: {
            type: 'category', 
            data: a,
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: { color:'#ccd0f2',fontSize: 20,interval: 0, rotate: 35}
        },
        yAxis: [{
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
          },
          {
            type: 'value',
            axisLine: {show:true},
            splitLine: { show: false},
            axisLabel: { show:true}
          }
        ],
        series: [{
            name:new Date().getFullYear()-1,
            type: 'bar',
            barWidth: '18%',
            data: c
          },
          {
            name:new Date().getFullYear(),
            type: 'bar',
            barWidth: '18%',
            data: b
          },
          {
            name:'同比',type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            yAxisIndex: 1,
            data: d
          }
        ]
			})
    },
    rightbar2(vals){
      let rightbar2Chart = echarts.init(this.$refs.rightbar2);
      let _arr = vals.sort(compare('data',0))
      let _name = []
      let _val = []
      for(var i in _arr){
        if(i<=5){
          if(_arr[i].trade=='信息传输、软件和信息技术服务业'){
            _arr[i].trade = '信息软件业'
          }
          _name.push(_arr[i].trade)
          _val.push(
            _arr[i].data?(_arr[i].data/10000).toFixed(2):0
          )
        }
      }
			rightbar2Chart.setOption({
        color:['#00ccff'],
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          formatter:'{b}: {c}万/千瓦时',
          axisPointer: {
            type: 'shadow' 
          }
        },
        grid: {
            left: '20%',right: '-10%', bottom: '6%', top:'6%'
        },
        xAxis: {
          type: 'value',show:false
        },
        yAxis: {
          type: 'category', axisLine:{ show:false}, axisTick:{ show:false},
          axisLabel: { color:'#fff',fontSize: 20},
          data: _name
        },
        series: [
          {
            name: '石油', type: 'bar', stack: 'total', barWidth:'35%',
            itemStyle:{ barBorderRadius: [0,12,12,0],},
            label: {
              show: true,fontSize: 20,color:'#fff',fontWeight:600,offset:[0,2],
            },
            data: _val
          },
        ]
			})
    },
    rightline1(name,val){
      let rightline1Chart = echarts.init(this.$refs.rightline1);
      common.moveEcharts(name.length,rightline1Chart)
			rightline1Chart.setOption({
        color:['#00ffcc'],
        legend: {
            icon: 'rect',
            itemWidth: 18, itemHeight: 8, itemGap: 20,right: '4%',
            data: ['增值服务企业'],
            textStyle: {
                fontSize: 20, color: '#fff'
            }
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'line' 
          }
        },
        grid: {
            left: '6%',right: '6%', bottom: '18%', top:'12%'
        },
        xAxis: {
            type: 'category', boundaryGap: true,
            data: name,
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: {
              show:true,
              formatter: '{value}%'
            }
        },
        series: [{
            name: '增值服务企业', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: val
          },
        ]
			})
    },
    rightline2(a,b,c){
      let rightline2Chart = echarts.init(this.$refs.rightline2);
      common.moveEcharts(a.length,rightline2Chart)
			rightline2Chart.setOption({
        color:['#ffff00','#00ffcc'],
        legend: {
            icon: 'rect',
            itemWidth: 18, itemHeight: 8, itemGap: 20,right: '8%',
            textStyle: {
                fontSize: 20, color: '#fff'
            }
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'line' 
          },
          formatter:'{b}(万/kwh)<br />{a0}: {c0}<br />{a1}: {c1}'
        },
        grid: {
            left: '8%',right: '8%', bottom: '18%', top:'12%'
        },
        xAxis: {
            type: 'category', boundaryGap: true,
            data: a.reverse(),
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { 
              show:true,
              formatter:'{value}%'
            }
        },
        series: [{
            name: '直购电企业', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: c.reverse()
          },{
            name: '常规用电企业', type: 'line',showSymbol: false,
            lineStyle: { normal: { width: 3 }},
            data: b.reverse()
          },
        ]
			})
    },
    rightbar3(a,b){
      let rightbar3Chart = echarts.init(this.$refs.rightbar3);
			rightbar3Chart.setOption({
        color:['#00ccff'],
        grid: {
            left: '10%',right: '6%', bottom: '18%', top:'12%'
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'shadow' 
          }
        },
        xAxis: {
            type: 'category', 
            data: a,
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            type: 'value', max:400,
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
        },
        series: [{
            type: 'bar',barWidth: '20%',
            data: b
          }
        ]
			})
    },


    myfun(val,sum) {
      var myChart = echarts.init(this.$refs.rightpie1);
      var data = [
        { name: "能源管理", value: val.synth==0||sum==0?0:(val.synth/sum*100).toFixed(0), gdp:val.synth},//value为百分比（取整）,gdp为值
        { name: "电气火灾", value: val.fire==0||sum==0?0:(val.fire/sum*100).toFixed(0), gdp:val.fire},
        { name: "智能运维", value: val.ops==0||sum==0?0:(val.ops/sum*100).toFixed(0), gdp:val.ops},
        { name: "直购电", value: val.direct==0||sum==0?0:(val.direct/sum*100).toFixed(0), gdp:val.direct},
        { name: "基本电费", value: val.basic==0||sum==0?0:(val.basic/sum*100).toFixed(0), gdp:val.basic},
      ];
      var titleArr = [];
      var seriesArr = [];
      var colors = [
        ["#00ccff", "#2b3473"],["#00ffcc", "#2b3473"],["#fac858", "#2b3473"],
        ["#eb6098", "#2b3473"],["#b486f3", "#2b3473"],
      ];
      data.forEach( (item, index) =>{
        titleArr.push({
          text: item.name,
          left: index * 20 + 10 +'%',
          top: '65%',textAlign: "center",
          textStyle: {
            fontWeight: "normal",fontSize: 22,color:'#ccd0f2',textAlign: "center"},
          },
          {
            text: item.gdp+' 家',
            left: index * 20 + 10 +'%',
            top: '75%',textAlign: "center",
            textStyle: {
              fontWeight: "normal",fontSize: 22,color:'#ccd0f2',textAlign: "center"},
          }
        );
        seriesArr.push({
          type: "pie",
          clockwise: false,
          radius: ['31%', '40%'],
          itemStyle: {
            normal: {
              color: colors[index][0],
              shadowColor: colors[index][0],
              shadowBlur: 0,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          hoverAnimation: false,
          center: [index * 20 + 10 +'%', '40%'],
          data: [
            {
              value: 100 - item.value,
              name: "invisible",
              itemStyle: {
                normal: {
                  color: colors[index][1],
                },
                emphasis: {
                  color: colors[index][1],
                },
              },
            },
            {
              value: item.value,
              label: {
                normal: {
                  formatter: function (params) {
                    return params.value + "%";
                  },
                  position: "center",
                  show: true,
                  textStyle: {
                    fontSize: 28,
                    fontWeight: "bold",
                    color:'#fff'
                  },
                },
              },
              itemStyle: {
                normal: {
                    color: colors[index][0],
                    borderWidth:5,
                    borderColor:colors[index][0],
                },
                emphasis: {
                    color: colors[index][0],
                }
              }
            },
          ],
        });
      });
      var option = {
        title: titleArr,
        series: seriesArr,
      };
      myChart.setOption(option);
    },

  },
  mounted() {
    let _codesNowPark = sessionStorage.getItem('nowAreaPark')//获取园区code
    let _codesNow = sessionStorage.getItem('nowArea')//获取各区域code
    // let _codesLast = JSON.parse(localStorage.getItem('regionCode'))[0]
    if(_codesNow){
      this.getAreaPowerPart(_codesNow)
    }else{
      // this.getAreaPowerPart(_codesLast[_codesLast.length-1])
      this.getAreaPowerPart(510600)
    }

    if(_codesNowPark){
      this.getParking(_codesNowPark)
    }else{
      this.getParking(510600)
    }

    this.getEnergyCount();//获取能源统计
    this.getTradeAnal();//查询左右中间两侧能源使用行业分析
    this.getCompanyDevNum();//获取左上角企业数等数量
    this.getRotaNumber();//获取右上角电水气表等数量
    this.getEightEnergyMax();//获取八大高能耗行业重点用能综合能耗
    this.getPowerDB();//获取能耗对比
    this.getAreaPowerData();//获取各区域能源使用占比分析
    this.searchSeverType();//查询右上角第二层服务类别
    this.getUpCompany();//获取增值企业占比
    this.getMonthPower();//获取能源消耗月使用量
    this.getCompanyPowerZb();//获取企业用电占比
  },
  beforeDestroy(){
    //清除所有定时器
    let _end = setInterval(function(){},2000);
    for(let i =1;i<=_end;i++){
      clearInterval(i)
    }
  },
}
</script>

<style lang="less" scoped>
.mainbox{ position: absolute; width: 100%; height: 100%; top:0; left:0; padding:70px 60px;
  .date-plug{
    z-index: 5;
    position: absolute;
    left: 25%;
  }
  .tongji{ display: flex; flex-direction: row; width: 100%; height: 4.4rem; flex-shrink: 0; z-index: 1000;
    li{ flex: 1; width: 33.33%; display: flex; flex-direction: row;
      .left{ width: 4.4rem; height: 4.4rem; flex-shrink: 0; margin-right: 1rem;
        img{ width: 100%; height: 100%;}
      }
      .right{ display: flex; flex-direction: column; justify-content: center;
        span{ color: #fff;}
        p{ margin-top: .5rem;
          b{ font-size: 2.3rem; font-family: 'DS-DIGIT'; margin-right: 10px;}
        }
      }
    }
  }
  .tongji1{
    li{ width: 25%;}
  }
  &>.left{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: left;
    .modulebox{ flex: 1; flex-shrink: 0;}
    .modulebox:nth-child(2){ flex: .75;}
    .modulebox:nth-child(3){ flex: 2;}
    .f_row{ height: 50%; flex-shrink: 0;
      .f_column{ width: 50%; flex-shrink: 0;}
    }
  }
  &>.right{ width: 1360px; height: 100%; display: flex; flex-direction: column; float: right;
    .modulebox{ flex: 1; flex-shrink: 0;}
    .modulebox:nth-child(2){ flex: 2;
      .f_row{
        .f_column:nth-child(1){ flex: 1.6; width: auto;}
      }
      .f_row:nth-child(1){ flex: .8; height: auto; flex-shrink: 0;}
      .f_row:nth-child(2){ flex: 1.2; height: auto; flex-shrink: 0;}
    }
    .f_row{ height: 50%; flex-shrink: 0;
      .f_column{ width: 50%; flex-shrink: 0;}
    }
  }
  .titlebox{ display: flex; flex-direction: row; align-items: center;
    .title{ flex: 1;}
    .select{ color: #00ccff; z-index: 99;
      .el-select{ width: 7rem; margin:0 1rem 0 .4rem;}
    }
  }

  .energylist{ display: flex; flex-direction: row; flex-wrap: wrap; padding:0 2rem 1rem; align-items: center;
    li{ width: 33.33%; flex-shrink: 0; display: flex; flex-direction: row;
      .left{ width: 3.5rem; height: 3.5rem; flex-shrink: 0; margin-right: 1rem;
        img{ width: 100%; height: 100%;}
      }
      .right{ display: flex; flex-direction: column; justify-content: center;
        span{ color: #fff;}
        p{
          b{ font-size: 1.6rem; font-weight: 500; margin-right: 10px;}
        }
      }
    }
    li:nth-child(1) p{ color: #00ffcc;}
    li:nth-child(2) p{ color: #00ccff;}
    li:nth-child(3) p{ color: #eb6098;}
    li:nth-child(4) p{ color: #b486f3;}
    li:nth-child(5) p{ color: #ffff00;}
  }
  .servicevalue{ display: flex; flex-direction: column; justify-content: space-evenly; padding:1rem 1rem 1rem 2rem;
    li{ display: flex; flex-direction: row; align-items: center;
      span:nth-child(1){ width: 8rem;}
      span:nth-child(2){ color: #00ffcc;}
      b{ font-size: 1.6rem; margin-right: 10px; font-weight: 500;}
    }
  }
  .energycontrast{ display: flex; flex-direction: column; justify-content: space-evenly; padding:0 1rem .4rem 2rem;
    li{ display: flex; flex-direction: column;
      p:nth-child(1){ margin-bottom: 4px;}
      p:nth-child(2){ font-size: .8rem; display: flex; flex-direction: row; align-items: center; justify-content: space-between; align-items: center;
        span:nth-child(1){ color: #00ffcc;}
        span:nth-child(2){ display: flex; flex-direction: row; align-items: baseline;}
        b{ font-size: 1.3rem; margin-right: 10px; font-weight: 500;}
        i{ font-weight: bold;}
      }
    }
  }
  .energyregion{ display: flex; flex-direction: row;
    &>div{ display: flex; flex-direction: column;
      h3{ color:#00ffcc; margin:5px 0 0 1rem; font-weight: 500; font-size: 1rem;}
    }
    &>div:nth-child(1){ flex: 1;}
    &>div:nth-child(2){ width: 45%; flex-shrink: 0;}
  }
  .energygdp{ display: flex; flex-direction: row;
    &>div{ display: flex; flex-direction: column; width: 50%; flex-shrink: 0;
      h3{ color:#00ffcc; margin:5px 0 0 1rem; font-weight: 500; font-size: 1rem;}
    }
  }
}
</style>
