// import html2Canvas from 'html2canvas'
// import JsPDF from 'jspdf'
// import XLSX from 'xlsx'
// import { Loading } from 'element-ui';

// export function htmlToPdf (Dom, title) {
//   /*
//     @Dom DOM元素
//     @title pdf文件名
//   */
//   const elloading = Loading.service({
//     lock: true,
//     text: 'PDF生成中...',
//     spinner: 'el-icon-loading',
//     background: 'rgba(0, 0, 0, 0.7)',
//     // target: Dom,
//   });

//   html2Canvas(Dom, {
//     allowTaint: true,
//   }).then(function (canvas) {


//     let contentWidth = canvas.width
//     let contentHeight = canvas.height
//     let pageHeight = contentWidth / 592.28 * 841.89
//     let leftHeight = contentHeight
//     let position = 0
//     let imgWidth = 595.28
//     let imgHeight = 592.28 / contentWidth * contentHeight
//     let pageData = canvas.toDataURL('image/jpeg', 1.0)
//     let PDF = new JsPDF('', 'pt', 'a4')
//     let index = 0
//     if (leftHeight < pageHeight) {
//       PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
//     } else {
    
//       while (leftHeight > 0) {
//         PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
//         leftHeight -= pageHeight

//         position -= 841.89 - index * 2

//         if (leftHeight > 0) {
//           PDF.addPage()
//         }
//         index++
//       }
//     }
//     PDF.save(title + '.pdf')
//     elloading.close();

//   }
//   )
// }
// export function ObjExportExcel (data, xlsxName) {//导出数组对象为xlsx  key就是header
//   // const header = {header: ['A','B'] }
//   // const data = [
//   //     {A:'1111',B:'2222'},
//   //     {A:'1111',B:'2222'},
//   //     {A:'1111',B:'2222'}
//   // ]
//   // var xlsxName ='myexcel'
//   // const ws = XLSX.utils.json_to_sheet(data,header) //改不了header

//   const ws = XLSX.utils.json_to_sheet(data)
//   const wb = XLSX.utils.book_new()
//   XLSX.utils.book_append_sheet(wb, ws, xlsxName)
//   XLSX.writeFile(wb, xlsxName + '.xlsx')


//   // let result = this.tableData.map((item) => {
//   //   return {
//   //     '日期': item.recordTime,
//   //     '增长率': item.growthRate+'%',
//   //   }
//   // })
//   // ObjExportExcel(result, '能耗数据'+ new Date().Format('yyyy-MM-dd'))
// }



export function compare (key, type) {//对象某一个值排序
  /*
    @key  对比的key名
    @type      0 升序  1降序
  */
  return function (a, b) {
    var value1 = a[key];
    var value2 = b[key];
    if (type == 0) {
      return value1 - value2;
    } else {
      return value2 - value1;
    }
  };
}


export function deepQuery (tree, children, key, id) {//通过id查找节点
  /*
    @tree  树数据
    @children  children key 名
    @key   对比的key名
    @id    对比的ID
  */
  var stark = []
  stark = stark.concat(tree)
  while (stark.length) {
    var temp = stark.shift()
    if (temp[children]) {
      stark = temp[children].concat(stark)
    }
    if (id == temp[key]) {
      return temp
    }
  }
}
// 将百度地图经纬度转换为腾讯/高德地图经纬度        用于大屏
export function bMapTransQQMap (lng, lat) {
  let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
  let x = lng - 0.0065;
  let y = lat - 0.006;
  let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
  let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
  let lngs = z * Math.cos(theta);
  let lats = z * Math.sin(theta);

  // return {
  //   lng: lngs,
  //   lat: lats        
  // }   
  return [lngs, lats];
}


