import axios from 'axios'

export default {
  //查询当前用户下的下级区域
  getRegionChild(){
    return axios.get('/show/map/getRegionChild');
  },
  //查询企业数，设备数，计量器具
  getCompanyDevCount(){
    return axios.get('/show/energy/getCompanyDevCount');
  },
  //查询电表水表等
  getCompanyIotDevCount(){
    return axios.get('/show/energy/getCompanyIotDevCount');
  },
  //查询能源统计
  getCompanyEnergy(data){
    return axios.get('/show/energy/getCompanyEnergy',data);
  },
  //查询八大高能耗行业重点用能综合能耗
  getEightTrade(){
    return axios.get('/show/energy/eightTrade');
  },
  //查询能耗对比
  getEnergyContrast(){
    return axios.get('/show/energy/energyContrast');
  },
  //查询能源使用区域分析
  getCompanyArea(data){
    return axios.get('/show/energy/getCompanyArea',data);
  },
  //查询服务类别
  getServiceModel(){
    return axios.get('/show/energy/serviceModel');
  },
  //查询能源使用行业分析
  getCompanyTrade(data){
    return axios.get('/show/energy/getCompanyTrade',data);
  },
  //查询增值企业占比
  getIncrementCompany(){
    return axios.get('/show/energy/incrementCompany');
  },
  //查询能源消耗月使用量
  getEnergyMonth(){
    return axios.get('/show/energy/energyMonth');
  },
  //查询园区企业用能统计
  getParkEnergy(data){
    return axios.get('/show/energy/parkEnergy',data);
  },
  //查询企业用电占比
  getCompanyEnergyPro(){
    return axios.get('/show/energy/companyEnergyPro');
  },

}